<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">ROLES</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="search"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearch()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">FIRST NAME</th>
                      <th class="text-center text-uppercase">LAST NAME</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_depan }}
                      </td>
                      <td class="text-center">
                        {{ data.nama_belakang }}
                      </td>
                      <td class="text-center">
                        <!-- <button
                        data-dismiss="modal"
                        aria-label="Close"
                        class="btn btn-primary btn-sm"
                        v-on:click="PilihClients(r.id_member, r.nama_depan)"
                      >
                        Pilih
                      </button> -->

                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihClients(data.id_member, data.nama_depan, data.nama_belakang)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="page--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
            <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE FILE </v-card-title>
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col v-if="errors.length" cols="12">
              <v-alert v-for="error in errors" :key="error" color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                id="nama_depan"
                v-model="databerkas.nama_depan"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Name Client"
                dense
                outlined
                readonly
                onkeydown="return false;"
              ></v-text-field>

              <text
                id="id_member"
                v-model="databerkas.id_member"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="ID Member"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Berkas </v-card-text>
              <img v-if="databerkas.berkas.length == 0" :src="defautlPhoto()" width="150px" />

              <div v-if="databerkas.berkas.length != 0">
                <v-row>
                  <v-col
                    v-for="(berkas, index) in databerkas.berkas"
                    :key="berkas.id_berkas"
                    cols="6"
                    sm="4"
                    style="cursor: pointer"
                  >
                    <!-- <v-img :src="ext == 'pdf' ? berkas.logo : berkas.berkas" @click="deletePhotoBerkas(index)">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img> -->

                    <v-img :src="ext == 'pdf' ? berkas.berkas[1] : berkas.berkas[1]" @click="deletePhotoBerkas(index)">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-img
                      :src="ext == 'pdf' ? berkas.berkas[0] : berkas.berkas[0]"
                      style="display: none"
                      @click="deletePhotoBerkas(index)"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <!-- {{ berkas.berkas[0] }} -->
                  </v-col>
                </v-row>
                <!-- <div v-for="(berkas, index) in databerkas.berkas" :key="berkas.id_berkas" style="cursor: pointer">
                <img :src="berkas.berkas" @click="deletePhotoBerkas(index)" />
              </div>  style="display: none"-->
              </div>
              <span v-if="databerkas.berkas.length != 0">*Klik gambar untuk menghapus!</span>
              <div>
                <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooser">
                  Choose File
                </v-btn>
              </div>

              <input
                id="berkas"
                ref="input"
                style="display: none"
                type="file"
                :name="databerkas.berkas"
                @change="uploadPhotoBerkas"
              />

              <!-- <input type="file" id="berkas" v-on:change="uploadPhotoBerkas" /> -->
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="databerkas.keterangan" label="Keterangan" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'berkas-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Files',
          disabled: false,
          href: '/admin/berkas',
        },
        {
          text: 'Create Files',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      ext: [],

      databerkas: {
        id_member: '',
        berkas: [],
        keterangan: '',
      },
      errors: [],

      clients: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  watch: {
    visa_rem() {
      this.pages = []
      this.setPages()
    },
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getAllClients()

      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    // showPhoto() {
    //   if (this.databerkas.berkas.length == 0) {
    //     let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/no-images.png`
    //     return imageDefault
    //   } else {
    //     let imageClient = process.env.VUE_APP_ROOT_API + `/` + this.clients.photo
    //     return imageClient
    //   }
    // },
    showFileChooser() {
      this.$refs.input.click()
    },
    uploadPhotoBerkas(e) {
      if (e.target.files[0].size > 2000000) {
        this.$swal.fire({
          title: 'Warning!',
          text: 'File size too large! File size must be smaller than 2MB!',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
        })
      } else {
        const readerPhoto = new FileReader()
        readerPhoto.readAsDataURL(e.target.files[0])
        readerPhoto.onload = res => {
          const data = {
            // berkas: res.target.result,
            berkas: [res.target.result, res.target.result],
          }
          const pdf = {
            berkas: [res.target.result, `${process.env.VUE_APP_ROOT_API}/images/berkas/pdf.jpg`],

            // berkas: res.target.result,
          }

          const pisahtitikkoma = data.berkas[1].split(';')

          // let pisahtitikkoma = data.berkas.split(';')
          const pisahgarismiring = pisahtitikkoma[0].split('/')
          const extension = pisahgarismiring[1]
          this.ext = extension

          if (this.ext == 'png' || this.ext == 'jpg' || this.ext == 'jpeg') {
            this.databerkas.berkas.push(data)
          } else if (this.ext == 'pdf') {
            this.databerkas.berkas.push(pdf)
          }

          // this.resetPhotoBerkas();
        }
      }
    },

    // defaultFile() {
    //   let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/pdf.jpg`
    //   return imageDefault
    // },
    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/berkas/no-images.png`

      return imageDefault
    },
    deletePhotoBerkas(index) {
      // splice(start, length)
      this.databerkas.berkas.splice(index, 1)
    },
    getAllClients() {
      // state token
      localStorage.getItem('token')

      const url = `${process.env.VUE_APP_ROOT_API}/api/members`

      return this.axios.get(url).then(response => {
        this.clients = response.data.member
      })
    },
    CreateData(e) {
      // console.log('id_member: ' + this.users.id_member)

      if (this.$data.databerkas.id_member.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Name client reqired !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.databerkas.berkas.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Berkas required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.databerkas.keterangan.length === 0) {
        // this.errors.push('Password harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Keterangan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        console.log(`BERKAS : ${this.databerkas.berkas[0].berkas}`)
        const uri = `${process.env.VUE_APP_ROOT_API}/api/berkas/store`

        this.axios
          .post(uri, {
            id_member: this.databerkas.id_member,

            // berkas: this.databerkas.berkas[0].berkas,
            berkas: this.databerkas.berkas,
            keterangan: this.databerkas.keterangan,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Berkas created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch()

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'berkas-index' })
        }, 3000)
      }
    },
    doSearch() {
      const go = this.clients.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_depan.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.clients)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.clients.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(clients) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return clients.slice(from, to)
    },

    PilihClients(id_member, nama_depan, nama_belakang) {
      document.querySelector('#id_member').setAttribute('value', id_member)
      document.querySelector('#nama_depan').setAttribute('value', `${nama_depan} ${nama_belakang}`)
      this.databerkas.id_member = id_member
      this.databerkas.nama_depan = nama_depan
    },
  },
}
</script>
